import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

const Section = ({
  className,
  smallSpacingTop,
  smallSpacingBottom,
  noSpacingTop,
  noSpacingBottom,
  hasDivider = false,
  children,
}) => {
  return (
    <>
      <section
        className={`${className ? className : ""}${
          smallSpacingTop ? " pt-sm" : ""
        }${smallSpacingBottom ? " pb-sm" : ""}${noSpacingTop ? " pt-0" : ""}${
          noSpacingBottom ? " pb-0" : ""
        }`.trim()}
      >
        {children}
      </section>
      {hasDivider && <hr className="divider container" />}
    </>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  smallSpacingTop: PropTypes.bool,
  smallSpacingBottom: PropTypes.bool,
  noSpacingTop: PropTypes.bool,
  noSpacingBottom: PropTypes.bool,
  hasDivider: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

Section.defautProps = {
  className: "",
  smallSpacingTop: false,
  smallSpacingBottom: false,
  noSpacingTop: false,
  noSpacingBottom: false,
  hasDivider: false,
};

export default Section;
