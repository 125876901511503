import { Link } from 'gatsby'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DashboardLayout from '../../components/Layout/DashboardLayout'

const AddNewAddress = () => {
  return (
    <DashboardLayout>
      <Link to="/dashboard/personal-detail" className="back">
        Back To Addresses
      </Link>
      <Form className="d-flex flex-column">
        <div className="edit-personal-detail">
          <h5>ADD NEW ADDRESSES</h5>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="FirstName">
                <Form.Label className="text-sm">First Name *</Form.Label>
                <Form.Control type="text" placeholder="First Name" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="LastName">
                <Form.Label className="text-sm">Last Name *</Form.Label>
                <Form.Control type="text" placeholder="Last Name" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="Company">
                <Form.Label className="text-sm">Company *</Form.Label>
                <Form.Control type="text" placeholder="Your Company" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="PhoneNumber">
                <Form.Label className="text-sm">Phone Number *</Form.Label>
                <Form.Control type="text" placeholder="Your Phone Number" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="Address1">
                <Form.Label className="text-sm">Address 1</Form.Label>
                <Form.Control type="text" placeholder="Your Address 1" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="Address12">
                <Form.Label className="text-sm">Address 2</Form.Label>
                <Form.Control type="text" placeholder="Your Address 2" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="City">
                <Form.Label className="text-sm">City *</Form.Label>
                <Form.Control type="text" placeholder="Your City" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="State">
                <Form.Label className="text-sm">State/Province *</Form.Label>
                <Form.Select
                  aria-label="Please select a region, state or province"
                  required
                >
                  <option>Please select a region, state or province</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="ZipCode">
                <Form.Label className="text-sm">Zip/Postal Code *</Form.Label>
                <Form.Control type="text" placeholder="Zip/Postal Code" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-4" controlId="Country">
                <Form.Label className="text-sm">Country *</Form.Label>
                <Form.Select aria-label="Please select a country" required>
                  <option>Please select a country</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <a href="#" className="btn btn-outline-primary mx-auto d-flex mt-3">
          Save Address
        </a>
      </Form>
    </DashboardLayout>
  )
}

export default AddNewAddress
