import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "..";
import Sidebar from "../../Sidebar";
import Section from "../Section";

const DashboardLayout = ({ children }) => {
  return (
    <Layout>
      <Section smallSpacingTop>
        <div className="dashboard">
          <Container>
            <Row>
              <Col lg={4} xxl={3} className="order-2 order-lg-1">
                <Sidebar />
              </Col>
              <Col lg={8} xxl={9} className="order-1 order-lg-2">
                <div className="dashboard-inner">{children}</div>
              </Col>
            </Row>
          </Container>
        </div>
      </Section>
    </Layout>
  );
};

export default DashboardLayout;
