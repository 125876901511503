import { Link } from "gatsby";
import React from "react";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <p className="subtitle subtitle-sm text-secondary">Welcome Back,</p>
      <h4 className="text-uppercase">AMI TRINH</h4>
      <ul>
        <li>
          <Link to="">My Products</Link>
        </li>
        <li>
          <Link to="/dashboard/my-order">My Order</Link>
        </li>
        <li>
          <Link to="">Wishlists</Link>
        </li>
        <li>
          <Link to="/dashboard/personal-detail">Personal Details</Link>
        </li>
        <li>
          <Link to="">Manage Addresses</Link>
        </li>
        <li>
          <Link to="">Payments & Credits</Link>
        </li>
        <li>
          <Link to="">Newsletter Subscriptions</Link>
        </li>
      </ul>

      <button className="btn btn-outline-primary btn-w230">Log Out</button>
    </div>
  );
};

export default Sidebar;
